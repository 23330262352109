/**
 * Favorites, uses the "module pattern" to add this module onto site global object
 */
var site = (function ($, site) {
  'use strict';

  // Add a new little index for our favs
  site.favs = site.favs || {};
  site.favs.FAVORITE_PRODUCTS = [];

  // Add an array of favorites to the collection
  site.favs.addFavs = function (favsArray) {
    // Quick cleanup, let's assume strings
    this.FAVORITE_PRODUCTS = _.map(favsArray, function (fav) {
      return fav.toString();
    });
    // Show visually on page
    this.visualUpdateFavs();
  };

  // Add single fav to this little app, and also call product_ui.js
  site.favs.addFav = function (skuBaseId) {
    this.FAVORITE_PRODUCTS.push(skuBaseId);
    prodcat.ui.addToFavorites({
      action: 'add',
      skuBaseId: skuBaseId
    });
    this.visualUpdateFavs();
    $(window).trigger('faves_rpc_success');
  };

  // Remove a single fav
  site.favs.removeFav = function (skuBaseId) {
    this.FAVORITE_PRODUCTS = _.without(this.getFavs(), skuBaseId);
    prodcat.ui.addToFavorites({
      action: 'delete',
      skuBaseId: skuBaseId
    });
    this.visualUpdateFavs();
    $(window).trigger('faves_rpc_success');
  };

  // Return all the favorites
  site.favs.getFavs = function () {
    return this.FAVORITE_PRODUCTS;
  };

  // When we change favs, show on page. Loop through just the items in our list
  site.favs.visualUpdateFavs = function () {
    // icon--heart (solid heart) overrides icon--heart-o (which is default)
    $('.js-add-to-favorites i').removeClass('icon--heart');
    $(this).focus();

    $('.icon--heart-o').one('click', function (e) {
      $(this).addClass('black-heart');
      $(this).one('mouseleave', function (e) {
        $(this).removeClass('black-heart');
      });
    });

    _.each(this.getFavs(), function (skuBaseId) {
      var selectorString = '.js-add-to-favorites[data-product*="' + skuBaseId + '"] i, .product__detail .js-add-to-favorites[data-default-fav*="' + skuBaseId + '"] i';

      $(selectorString).addClass('icon--heart');
    });
  };

  site.favs.isFavorite = function (skuBaseId) {
    return _.contains(this.getFavs(), skuBaseId);
  };

  return site;
})(jQuery, site || {});

/**
 * Drupal behavior to show DOM changes for favorites (stores state in
 * site.favs.FAVORITE_PRODUCTS)
 */
(function ($, Drupal) {
  'use strict';

  // Fired on every sku change for full $products
  Drupal.behaviors.favToolTips = {
    attach: function (context) {
      var $favLinks = $('.js-add-to-favorites', context);
      var favs = site.favs;

      favs.visualUpdateFavs(); // Show solid/outline based on site.favs.getFavs()

      // Apply tooltips, http://iamceege.github.io/tooltipster/
      $favLinks.tooltipster({
        arrow: false,
        // autoClose: false,
        contentAsHTML: true,
        interactive: true,
        multiple: true,
        offsetX: 10,
        animation: 'grow',
        position: 'top-left',
        theme: 'mac-tooltip',
        speed: 500,
        timer: 3000,
        trigger: 'click',
        updateAnimation: false
      });

      // Actual click event
      $favLinks.on('click', function (e) {
        e.preventDefault();
        var skuBaseId = $(this).data('product').SKU_BASE_ID;

        // If skuBaseId is empty string, we're probably looking at an MPP. In that
        // case, use the default skuBaseId from the data attr
        // @TODO: this is borked, since it will trigger for ALL favorites not default
        if (_.isEmpty(skuBaseId)) {
          skuBaseId = $(this).data('default-fav');
        }

        if (favs.isFavorite(skuBaseId)) {
          $(this).tooltipster('content', $(this).data('tooltip-removed'));
          $(this).attr('aria-checked', 'false');
          favs.removeFav(skuBaseId);
        } else {
          $(this).tooltipster('content', $(this).data('tooltip-added'));
          $(this).attr('aria-checked', 'true');
          favs.addFav(skuBaseId);
        }
      });
    },

    attached: false
  };
})(jQuery, Drupal);

/**
 * Ride the gnavdata_success event and use it's data to kick off our little
 * favorites object.
 */
(function ($) {
  'use strict';

  $(window).on('gnavdata_success', function (e, gData) {
    if (_.isNull(gData)) {
      return;
    }
    site.favs.addFavs(gData.FAVORITE_PRODUCTS);
  });
})(jQuery);
